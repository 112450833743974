import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardContext from './MessageViewCardContext';
import MessageViewCardUserLink from './MessageViewCardUserLink';

/**
 * Renders the link to the author page for the Message related to the MessageViewCard
 * @author Rosalyn Rowe
 */
const MessageViewCardAuthorLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cx = useClassNameMapper(localStyles);
  const { message } = useContext(MessageViewCardContext);

  return (
    <MessageViewCardUserLink className={cx('lia-g-divider lia-byline-item')}>
      {message.author.login}
    </MessageViewCardUserLink>
  );
};

export default MessageViewCardAuthorLogin;
