import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';
import { createContext } from 'react';
import type { ItemType, MessageViewVariant } from '../../../../types/enums';
import type { ItemViewVariantFCProps } from '../../../entities/types';

/**
 * By making this information available to any component within the MessageViewCard folder,
 * we enable ourselves to remove the inline components in MessageViewCard and replace them with
 * more performant FCs -- cleaning up the code and gaining speed at the same time!
 */
export interface MessageViewCardContextInterface
  extends Omit<
    ItemViewVariantFCProps<Message, ItemType.MESSAGE, MessageViewVariant.CARD>,
    'entity'
  > {
  message: Message;
}

export default createContext<MessageViewCardContextInterface>(null);
