import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import NodeLink from '../../../nodes/NodeLink/NodeLink';
import useTranslation from '../../../useTranslation';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardContext from './MessageViewCardContext';

/**
 * Wrapper around NdoeLink specifically for MessageViewCard.
 * Renders a link to the board related to the message.
 * @author Rosalyn Rowe
 */
const MessageViewCardNodeLinkWrapper: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cx = useClassNameMapper(localStyles);
  const { message, useNodeHoverCard, useShortTitle } = useContext(MessageViewCardContext);
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_VIEW_CARD);
  const {
    board: { shortTitle, title }
  } = message;
  const byLineBoardTitle = useShortTitle && shortTitle ? shortTitle : title;

  return (
    <NodeLink
      className={cx('lia-g-divider lia-byline-item')}
      node={message.board}
      useHoverCard={useNodeHoverCard}
      ariaLabel={formatMessage('gotoParent', { name: message.board.title })}
    >
      {byLineBoardTitle}
    </NodeLink>
  );
};

export default MessageViewCardNodeLinkWrapper;
