import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardAuthorLogin from './MessageViewCardAuthorLogin';
import MessageViewCardAuthorRank from './MessageViewCardAuthorRank';
import MessageViewCardContext from './MessageViewCardContext';
import MessageViewCardNodeLinkWrapper from './MessageViewCardNodeLinkWrapper';

/**
 * Renders the entire 'byline' for MessageViewCard
 * @author Rosalyn Rowe
 */
const MessageViewCardByline: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cx = useClassNameMapper(localStyles);
  const { useAuthorLogin, useAuthorRank, useNodeLink } = useContext(MessageViewCardContext);

  return (
    <div className={cx('lia-byline')}>
      <div className={cx('lia-byline-text')}>
        {useAuthorLogin && <MessageViewCardAuthorLogin />}
        {useAuthorRank && <MessageViewCardAuthorRank className={cx({ 'lia-dot': useNodeLink })} />}
        {useNodeLink && <MessageViewCardNodeLinkWrapper />}
      </div>
    </div>
  );
};

export default MessageViewCardByline;
