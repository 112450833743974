import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardContext from './MessageViewCardContext';

interface Props {
  /**
   * The class name for author rank.
   */
  className?: string;
}

/**
 * Wrapper around UserRank for use with MessageViewCard
 * @author Rosalyn Rowe
 */
const MessageViewCardAuthorRank: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const cx = useClassNameMapper(localStyles);
  const { message } = useContext(MessageViewCardContext);

  return <UserRank className={cx('lia-rank lia-byline-item', className)} user={message.author} />;
};

export default MessageViewCardAuthorRank;
