import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import UserLink from '../../../users/UserLink/UserLink';
import MessageViewCardContext from './MessageViewCardContext';

interface Props {
  /** Class name(s) to go on the UserLink component */
  className: string;
}

/**
 * Wrapper around UserLink specifically for MessageViewCard
 * Renders a link to the author profile related to the message
 * @author Rosalyn Rowe
 */
const MessageViewCardUserLink: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children
}) => {
  const cx = useClassNameMapper();
  const { message, useUserHoverCard } = useContext(MessageViewCardContext);

  return (
    <UserLink user={message.author} className={cx(className)} useHoverCard={useUserHoverCard}>
      {children}
    </UserLink>
  );
};

export default MessageViewCardUserLink;
