import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { MessageTimestamp } from '../../../../types/enums';
import MessageLink from '../../MessageLink/MessageLink';
import { MessageListMenuItem } from '../../MessageListMenu/MessageListMenu';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardTime from './MessageViewCardTime';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';

type Props = {
  message: Message;
  timeStampType: MessageTimestamp;
  useMessageTimeLink: boolean;
};

/**
 * Conditionally renders the MessageTime as a link for a MessageViewCard
 * Intended to be used directly from MessageViewCard
 * @author Rosalyn Rowe
 */
const MessageViewCardTimeWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  timeStampType,
  useMessageTimeLink
}) => {
  const cx = useClassNameMapper(localStyles);

  const item = MessageListMenuItem.POST_TIME_DESC;
  const queryParam =
    timeStampType === MessageTimestamp.LAST_POSTING_ACTIVITY_TIME &&
    message?.lastPublishTime != message?.conversation?.lastPostingActivityTime
      ? { topicRepliesSort: item, [EndUserQueryParams.AUTO_SCROLL]: 'true' }
      : null;

  const className = cx('lia-timestamp');

  return useMessageTimeLink ? (
    <MessageLink message={message} query={queryParam} className={className}>
      <MessageViewCardTime />
    </MessageLink>
  ) : (
    <MessageViewCardTime className={className} />
  );
};

export default MessageViewCardTimeWrapper;
