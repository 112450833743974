import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import MessageTime from '../../MessageTime/MessageTime';
import MessageViewCardContext from './MessageViewCardContext';

interface Props {
  /** Class name(s) to go on the MessageTime component */
  className?: string;
}

/**
 * Wrapper around MessageTime specifically for use with MessageViewCard
 * Intended to be used with the MessageViewCardTimeWrapper component
 * @author Rosalyn Rowe
 */
const MessageViewCardTime: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const cx = useClassNameMapper();
  const { message, timeStampType } = useContext(MessageViewCardContext);

  return (
    timeStampType && (
      <MessageTime
        message={message}
        className={cx(className)}
        timestampType={timeStampType}
        usePreferredDateDisplayStyle
      />
    )
  );
};

export default MessageViewCardTime;
